<template>
    <div class="user-index">
        <div class="main-h1">Дела</div>
        <div class="card">
            <div class="card-body">
                <div class="card-body-title">
                    <div class="row">
                        <div class="col-3">
                            Список дел
                        </div>
                        <div class="col-3 dates">
                            <input type="date" class="date date_from" v-model="date_from" @change="filterDate"/>
                            <input type="date" class="date date_to"  v-model="date_to" @change="filterDate"/>
                        </div>
                        <div class="col-6" v-if="$store.state.isAdmin()">
                            <div class="city_btns flex">
                                <div class="btn blue sm active" @click="selectCity(0,$event)">Все города</div>
                                <div class="btn blue sm" v-for="city in cities" @click="selectCity(city.id,$event)" data-city_id="{{city.id}}">{{city.title}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <claim-list :list="list_filtered" class="mb-20"/>
                <!--
                <router-link v-if="$store.state.isAdmin()" to="/panel/claim/create" class="btn green">Создать дело</router-link>
                <router-link v-if="$store.state.isClaimant()" to="/panel/claim/create_claimant" class="btn green">Подать иск</router-link>
                -->
            </div>
        </div>
    </div>
</template>

<script>
import claimList from '../../../components/claim/list.vue';
import moment from "moment";
export default {
    name: "index",
    data() {
        return {
            list: [],
            list_filtered:[],
            cities:[],
            city_selected:0,
            date_from: moment().subtract(1,'year').format('Y-MM-DD'),
            date_to: moment().add(1, 'year').format('Y-MM-DD'),
        }
    },
    components: {claimList},
    methods: {
        getList() {
            this.$api.get(`claim`).then(res => {
                if (res.data.success) {
                    this.list = res.data.data.sort((a, b) => {
                        let stageSortA = 9;
                        let stageSortB = 9;
                        switch (a.stage_id) {
                            case '7':stageSortA = 1;break
                            case '8':stageSortA = 1;break
                            case '9':stageSortA = 1;break
                            case '10':stageSortA = 1;break
                        }
                        switch (b.stage_id) {
                            case '7':stageSortB = 1;break
                            case '8':stageSortB = 1;break
                            case '9':stageSortB = 1;break
                            case '10':stageSortB = 1;break
                        }
                        let numberA_array = a.title.split('-');
                        let numberA = `s${stageSortA}b${numberA_array[4]}b${numberA_array[2]}`
                        let numberB_array = b.title.split('-');
                        let numberB = `s${stageSortB}b${numberB_array[4]}b${numberB_array[2]}`
                        if (numberA > numberB){
                            return -1;
                        }
                        if (numberA < numberB){
                            return 1;
                        }
                        return 0;
                    })
                    this.selectCity(0)
                } else {
                    //this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getCities() {
            this.$api.get(`city`).then(res => {
                if (res.data.success) {
                    this.cities = res.data.data
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        selectCity(city_id,event = false) {
            this.city_selected = city_id;
            if (this.city_selected == 0) {
                this.list_filtered = this.list;
            } else {
                this.list_filtered = this.list.filter(item => {return item.city_id == this.city_selected});
            }
            this.list_filtered = this.list_filtered.filter(item => {return item.stage_dl >= this.date_from && item.stage_dl <= this.date_to});
            if (event) {
                document.querySelector('.city_btns .btn.active').classList.remove('active');
                event.target.classList.add('active');
            }
        },
        filterDate() {
            if (this.city_selected == 0) {
                this.list_filtered = this.list;
            } else {
                this.list_filtered = this.list.filter(item => {return item.city_id == this.city_selected});
            }
            this.list_filtered = this.list_filtered.filter(item => {return item.stage_dl >= this.date_from && item.stage_dl <= this.date_to});
        }
    },
    created() {
        this.getList();
        this.getCities();
    }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/variables";
.city_btns {
    justify-content: flex-end;
    gap:10px;
    @media (max-width: $max-width) {
        justify-content: flex-start;
        width: 100%;
    }
    .btn {
        &.active {background: $cl-green;}
    }
}
.dates {
    display: flex;
    justify-content: space-between;
    .date {
        width: 48%;
    }
}
</style>